<template>
  <div class="user-list">
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入昵称/手机号搜索" @input="kwChange" class="mr20" />
        <el-select v-model="tableParams.levelConfigId" placeholder="会员等级" @change="reload" clearable>
          <el-option v-for="item in vipLevelOpt" :key="item.levelConfigId" :label="item.levelName"
            :value="item.levelConfigId">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onExport()">导出</el-button>
      </div>
    </section>
    <div style="width: 50%;display: flex;margin-bottom: 20px;">
  
    <div class="desc_item">总用户数：{{ headInfo.total }}</div>
    <div class="desc_item">今日新增用户：{{ headInfo.today }}</div>
    <div class="desc_item">7天新增用户：{{ headInfo.last7Days }}</div>
    <div class="desc_item">30天新增用户：{{ headInfo.last30Days}} </div>
    </div>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }">
     <el-table-column label="用户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.nickName || "-" }}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ scope.row.userMobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户id" align="center" prop="memberId">
      </el-table-column>
      <el-table-column label="注册时间" align="center" prop="addDt">

      </el-table-column>
      <el-table-column label="最近访问时间" align="center" prop="updDt">
      </el-table-column>
      <el-table-column label="最近下单时间" align="center" prop='createOrderTime'>
      </el-table-column>
      <el-table-column label="累计完成订单时长" align="center" prop="total">
      </el-table-column>
      <el-table-column label="成为会员时间" align="center" prop="memberLevelVo.firstLevelTime">
      </el-table-column>
      <el-table-column label="会员等级" align="center" prop="memberLevelVo.levelConfigVo.levelName">
      </el-table-column>
      <el-table-column label="历史累计充值（本金）" align="center">
        <template  slot-scope="scope">
         <span>{{ '￥'+scope.row.historyTotalRecharge || ''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="累计充值（本金）" align="center" >
        <template  slot-scope="scope">
         <span>{{ '￥'+scope.row.totalRecharge || ''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="成长值" align="center" prop="memberLevelVo.experience">
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click="goDetail(scope.row)">用户详情</el-button>
          <el-button type="text" size="medium" @click="setLevel(scope.row)">设置会员等级</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="infoShow" title="设置会员等级" :close-on-click-modal="false" width="30%" center @close="activeName='0'">
      <el-form :model="levelForm">
        <div style="border-bottom: 1px solid #ccc;padding-bottom: 20px;margin-bottom: 20px;">
          <span style="margin-right: 20px;">累计充值本金</span>
          <span>{{ '￥'+totalRecharge }}</span>
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane v-for="(item, index) in tabList" :key="index" :label="item.label" :name="item.id"></el-tab-pane>
        </el-tabs>

        <el-form-item label="赠送成长值" label-width="120px" v-if="activeName == 0">
          <el-input v-model="levelForm.experience" type="number" :step="1" placeholder="请输入赠送成长值" @keydown.native="(e) =>
      (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
        ? false
        : e.returnValue)
        " @input="numberInput"></el-input>
       
        </el-form-item>

        <el-form-item label="当前会员等级" label-width="120px" v-if="activeName == 1">
          <el-select v-model="levelForm.levelConfigId" placeholder="请选择会员等级" clearable>
            <el-option v-for="item in vipLevel" :key="item.levelConfigId" :label="item.levelName"
              :value="item.levelConfigId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ObjToOpt } from "@/utils/utils";

import { getPUserList, getPUserListExport,getPUserCount } from "@/api/user/user";
import { getLevelConfig, addMemberExperience,updateMemberLevel } from '@/api/user/level.js'

export default {
  data() {
    return {
     
      ObjToOpt,
      // table列表
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      headInfo:{},
      infoShow: false,
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "", //查询关键字
      },
      levelForm: {
        memberId:'',
        levelConfigId:'',
        experience:0
      },
      activeName: 0,
      tabList: [{

        label: '成长值',
        id: '0'
      }, {

        label: '会员等级',
        id: '1'
      }],
      vipLevel:[],
      vipLevelOpt:[],
      totalRecharge:''//累计充值本金
    };
  },
  mounted() {
    this.getPlateformUserList();
    this.getPUserCount()
    this.getLevelOpt()
  },
  watch: {
   
    
  },
  methods: {
    numberInput(value) {
      if (value.length > 8) { this.levelForm.experience = value.slice(0, 8) }
    },
    // 获取头部数据
    getPUserCount() {
      getPUserCount().then(res => {
       this.headInfo=res.data
      })
    },
    // 获取搜索下拉会员等级
    getLevelOpt() {
      getLevelConfig({ enable: true }).then((res) => {
        if (res.isSuccess == "yes") {
        this.vipLevelOpt=res.data
        }
      });
    },
    // 获取设置会员等级中的等级列表（会过滤等级）
    getLevelList(userEX) {
      getLevelConfig({ enable: true }).then((res) => {
        if (res.isSuccess == "yes") {
          this.vipLevel = res.data.filter((item) => {
            return item.experience >= userEX;
          });
        }
      });
    },
    // 【请求】商户列表
    getPlateformUserList() {
      getPUserList(this.tableParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getPlateformUserList();
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getPlateformUserList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.pageSize = size;
      this.reload();
    },

    // 【监听】导出
    onExport() {

      getPUserListExport(this.tableParams);
    },

   
    // 【监听】用户详情
    goDetail(row) {
      this.$router.push({
        path: "/vip/userInfo/baseInfo",
        query: {
          memberId: row.memberId,
        },
      });
    },
    // 点击设置会员等级
    setLevel(row){
      this.infoShow = true
      this.totalRecharge=row.totalRecharge
      this.levelForm.memberId = row.memberId
      this.levelForm.levelConfigId= row.memberLevelVo.levelConfigId

      if(row.memberLevelVo.levelConfigVo){
        this.getLevelList(row.memberLevelVo.levelConfigVo.experience)
      }else{
        this.getLevelList(0)
      }
      
    },
     // 【监听】表单提交
     onSubmit() {
      if (this.activeName == 0) {
        
        addMemberExperience(this.levelForm).then(res=>{
          this.reload()
          this.levelForm.experience = 0
          this.infoShow=false
        })
      }else{
        updateMemberLevel(this.levelForm).then(res=>{
          this.reload()
          this.levelForm.levelConfigId=''
          this.infoShow=false
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  margin-bottom: 20px;

  .tab_item {
    width: 80px;
  }

  .active {
    color: #409eff;
  }
}
.desc_item{
  margin-right: 20px;
  font-weight: 500;
}
.user-list {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>
