import request from "@/utils/request";
// 添加等级配置
export function addLevelConfig(data) {
  return request("post", "/aps/config/level/addLevelConfig", data);
}
// 编辑等级配置
export function editLevelConfig(data) {
  return request("post", "/aps/config/level/updateLevelConfig", data);
}
// 获取等级配置
export function getLevelConfigDetail(data) {
  return request("post", "/aps/config/level/getLevelConfig", data);
}
// 根据服务商获取当前等级列表
export function getLevelConfig(data) {
  return request("post", "/aps/config/level/getLevelConfigList", data);
}
//  添加权益
export function addLevelEquity(data) {
    return request("post", "/aps/config/level/addLevelEquity", data);
  }
  //  删除权益
export function deleteLevelEquity(data) {
  return request("post", "/aps/config/level/deleteLevelEquity", data);
}
 //  隐藏权益
 export function hideLevelEquity(data) {
  return request("post", "/aps/config/level/displayLevelEquity", data);
}
//  权益列表
export function getLevelEquityList(data) {
    return request("post", "/aps/config/level/getLevelEquityList", data);
  }
//  获取权益详情
export function getLevelEquityDetail(data) {
    return request("post", "/aps/config/level/getLevelEquity", data);
  }
// 权益值
export function cardEquityEnumVo(data) {
    return request("get", "/aps/config/level/cardEquityEnumVo", data);
  }
  //  更新权益
export function updateLevelEquity(data) {
  return request("post", "/aps/config/level/updateLevelEquity", data);
}
// 增加会员经验
export function addMemberExperience(data) {
  return request("post", "/apm/ums/shop/user/addMemberExperience", data);
}  
// 更新会员等级
export function updateMemberLevel(data) {
  return request("post", "/apm/ums/shop/user/updateMemberLevel", data);
}  
// 权益排序
export function sortLevelEquity(data) {
  return request("post", "/aps/config/level/sortLevelEquity", data);
}  
  //  根据会员积分权益
  export function getPointsEquity(data) {
    return request("post", "/aps/config/level/getPointsEquity", data);
  }